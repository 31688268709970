<template>
  <div>
    <template
      v-if="formattedImage.isImage"
    >
      <template
        v-if="formattedImage.url"
      >
        <img
          :src="item.blobUrl"
          class="display: none;"
        >
      </template>
      <template
        v-else-if="formattedImage.data"
      >
        <img
          :src="formattedImage.data"
          class="display: none;"
        >
      </template>
    </template>
    <template
      v-else
    >
      <v-alert
        v-if="!loading.visible"
        title="Error"
        type="error"
      >
        El tipo de fichero no es compatible
      </v-alert>
    </template>
  </div>
</template>

<script>
import _ from '@/utils/lodash'
import {  get } from 'vuex-pathify'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formattedImage () {
      const relativeURLField = 'url'
      const dataField = 'data'
      const typeField = 'type'
      const image = _.cloneDeep(this.item)
      const type = _.resolveProperty(typeField, image) || 'application/octet-stream'
      if (type) {
        this.$set(image, 'isImage', type.startsWith('image'))
      } else {
        this.$set(image, 'isImage', false)
      }
      if (relativeURLField in image) {
        this.$set(image, 'absoluteMediaURL', this.$api.getMediaFileURL(image[relativeURLField]))
      } else {
        const data = _.resolveProperty(dataField, image)
        if (data && type) {
          this.$set(image, 'data', `data:${type};base64,${data}`)
        }
      }
      const backgroundImage = new Image()
      backgroundImage.src = image.blobUrl
      backgroundImage.onload = () => {
        this.$emit('image-loaded', { canvasWidth: backgroundImage.width, canvasHeight: backgroundImage.height, image: image })
      }
      return image
    },
    loading: get('loading'),
  },
}
</script>